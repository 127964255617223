import { FunctionComponent, useEffect, useState } from "react";
import {
  Icon,
  useScreenSizeContext,
} from "@bluebottlecoffee/design-system/components";
import {
  AdvancedMarker,
  Map,
  MapEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import {
  centerOfUSA,
  getInitialMapZoom,
} from "../../lib/component-utils/cafe-search-fns";

export type CafeMarker = {
  name: string;
  position: {
    lat: number;
    lng: number;
  };
};

export type CafeSearchMapProps = {
  cafeMarkers?: CafeMarker[];
  initialCenter?: google.maps.LatLngLiteral;
  initialZoom: number;
  onIdle: (map: MapEvent<unknown>) => void;
  getCafeMarkerSelected: (cafeMarker: CafeMarker["name"]) => void;
  mapId: string;
};

/**
 * Utilizes react-google-maps package, renders Map component that displays cafe
 * markers and allows user to interact with the map and select a cafe
 */
export const CafeSearchMap: FunctionComponent<CafeSearchMapProps> = ({
  cafeMarkers,
  initialCenter,
  initialZoom,
  onIdle,
  mapId,
  getCafeMarkerSelected,
}) => {
  const [cafeMarkerSelected, setCafeMarkerSelected] = useState(null);
  const { isDesktop, isLgTablet, isMobile } = useScreenSizeContext();

  // use api hook to get map object and set camera movement to pan rather than
  // jump when the mapCenter changes
  const map = useMap(mapId);

  const handleMarkerClick = (cafeMarker: CafeMarker): void => {
    setCafeMarkerSelected(cafeMarker.name);
    // avoid zooming back out if the user has already zoomed in and only
    // zoom in if the current view is below the minToZoom level
    const zoomMinThreshold: number = 11;
    const zoomToLevel: number = 12;
    if (map?.getZoom() < zoomMinThreshold) {
      map?.setZoom(zoomToLevel);
    }

    const scrollMobileThreshold: number = 250;
    const scrollDesktopThreshold: number = 150;
    if (isMobile() && window.scrollY < scrollMobileThreshold) {
      // scroll down to reveal the cafe card list below the map
      window.scrollTo({ top: scrollMobileThreshold, behavior: "smooth" });
    } else if (
      (isDesktop() || isLgTablet()) &&
      window.scrollY > scrollDesktopThreshold
    ) {
      // scroll up to reveal the map above the cafe card list
      window.scrollTo({ top: scrollDesktopThreshold, behavior: "smooth" });
    }

    map?.panTo(cafeMarker.position);
  };

  useEffect(() => {
    map?.setCenter(initialCenter);
    map?.setZoom(initialZoom);
  }, [map, initialCenter, initialZoom]);

  useEffect(() => {
    getCafeMarkerSelected?.(cafeMarkerSelected);
  }, [cafeMarkerSelected]);

  return (
    <Map
      defaultCenter={initialCenter ?? centerOfUSA}
      defaultZoom={initialZoom ?? getInitialMapZoom()}
      disableDefaultUI
      id={mapId}
      gestureHandling="cooperative"
      maxZoom={18}
      minZoom={3}
      reuseMaps
      style={{
        width: "100%",
        height: "100%",
      }}
      zoomControl
      // mapId connects the map to custom style in google cloud console
      // https://console.cloud.google.com/google/maps-apis/studio/styles/381754a19710815e?project=blue-bottle-coffee-4b969
      mapId="e60112b7748353db"
      onIdle={onIdle}
    >
      {cafeMarkers?.map((cafeMarker, i) => (
        <AdvancedMarker
          clickable
          position={{
            lat: cafeMarker.position.lat,
            lng: cafeMarker.position.lng,
          }}
          onClick={() => handleMarkerClick(cafeMarker)}
          zIndex={cafeMarker.name === cafeMarkerSelected ? 80 : i + 5}
        >
          <Icon
            name="bbcMapPin"
            outline={cafeMarker.name === cafeMarkerSelected}
          />
        </AdvancedMarker>
      ))}
    </Map>
  );
};
