import React, { FunctionComponent } from "react";
import { useRouter } from "next/router";
import {
  CafeLocator,
  CafeLocatorProps,
} from "@bluebottlecoffee/design-system/components";
import { CafeLocatorFullCopy } from "../../lib/transformers/cafe-locator";
import { CafeSearchBar } from "./CafeSearchBar";

export interface CafeSearchLocatorProps {
  lang: string;
  region: string;
  cafeLocatorProps: Omit<
    CafeLocatorProps,
    | "copy"
    | "locationSearchFormProps"
    | "queryString"
    | "totalResultsDisplay"
    | "viewMoreButton"
  > & {
    copy: CafeLocatorFullCopy;
  };
}

export const CafeSearchLocator: FunctionComponent<CafeSearchLocatorProps> = ({
  cafeLocatorProps,
  region,
  lang,
}) => {
  const router = useRouter();

  const handleSuggestionClick = ({ placeId }) => {
    const placeIdQuery = placeId ? `?placeId=${placeId}` : "";

    const url = `/${region}/${lang}/cafes/search-results${placeIdQuery}`;
    router.push(url, undefined, { shallow: true });
  };

  const { searchButton, searchInputPlaceholder } = cafeLocatorProps.copy;

  return (
    <CafeLocator
      {...cafeLocatorProps}
      totalResultsDisplay={3}
      SearchBar={
        <CafeSearchBar
          onSuggestion={handleSuggestionClick}
          SearchCopy={{
            searchButton,
            searchInputPlaceholder,
          }}
        />
      }
    />
  );
};
