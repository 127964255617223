import {
  SearchBar,
  SearchBarProps,
} from "@bluebottlecoffee/design-system/components";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useInstantSearch } from "react-instantsearch";
import {
  centerOfUSA,
  getCountry,
  getInitialMapZoom,
} from "../../lib/component-utils/cafe-search-fns";

interface CafeSearchBarProps {
  mapId?: string;
  onSuggestion?: ({ placeId }: { placeId: string }) => void;
  newAddressName?: string;
  SearchCopy: SearchBarProps["copy"];
}

export const CafeSearchBar = ({
  mapId,
  onSuggestion,
  newAddressName,
  SearchCopy,
}: CafeSearchBarProps) => {
  const [addressName, setAddressName] = useState(null);
  const { refresh: refreshHits } = useInstantSearch();
  const [loading, setLoading] = useState(false);

  // Router for query params
  const router = useRouter();
  const { placeId: placeIdQuery } = router.query as { placeId: string };

  // react-google-maps
  const map = useMap(mapId);
  const places = useMapsLibrary("places");

  const defaultZoom = getInitialMapZoom();

  const goToCenterOfUSA = () => {
    map.setCenter(centerOfUSA);
    map.setZoom(defaultZoom);
  };

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    if (!places) return undefined;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());

    if (!map) return undefined;
    setPlacesService(new places.PlacesService(map));

    return () => setAutocompleteService(null);
  }, [places, map]);

  const fetchPredictions = useCallback(
    async (value: string) => {
      if (!autocompleteService || !value) {
        setPredictionResults([]);
        setLoading(false);
        return;
      }

      const request = { input: value, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
      setLoading(false);
    },
    [autocompleteService, sessionToken],
  );

  // Prevents the API from being called on every keystroke
  const debouncedFetch = useCallback(debounce(fetchPredictions, 200), [
    fetchPredictions,
  ]);
  const onInputChange = useCallback(debouncedFetch, [debouncedFetch]);

  const detailRequestOptions = (placeId: string) => ({
    placeId,
    fields: ["geometry", "formatted_address"],
    sessionToken,
  });

  const detailsRequestCallback = (
    placeDetails?: google.maps.places.PlaceResult | null,
  ) => {
    refreshHits();
    setAddressName(placeDetails.formatted_address);
    setSessionToken(new places.AutocompleteSessionToken());

    // Note: if the user search for united states, We will center the map to the center of the USA
    const country = getCountry(placeDetails.geometry.location);

    if (country) {
      map.setCenter(country.latLng);
      map.setZoom(country.zoom ?? defaultZoom);
    } else {
      map.fitBounds(placeDetails.geometry?.viewport);
    }
  };

  const placesServiceCallback = (id: string) =>
    placesService?.getDetails(detailRequestOptions(id), detailsRequestCallback);

  // Load place details and fit map bounds the first time a placeId is provided in the query
  useEffect(() => {
    if (!placeIdQuery || !map || !placesService) {
      // No placeId in the query, so we go to the center of the USA
      if (map && !placeIdQuery) goToCenterOfUSA();
      return;
    }

    placesServiceCallback(placeIdQuery);
  }, [placeIdQuery, map, placesService]);

  useEffect(() => {
    if (newAddressName) setAddressName(newAddressName);
  }, [newAddressName]);

  const handleSuggestion = (placeId: string | undefined) => {
    if (loading) return;
    if (map && placeId) {
      placesServiceCallback(placeId);
    } else if (onSuggestion) {
      onSuggestion({ placeId });
    } else {
      goToCenterOfUSA();
    }
  };

  return (
    <SearchBar
      onSubmit={() => handleSuggestion(predictionResults[0]?.place_id)}
      onChange={(value) => handleSuggestion(value.id)}
      options={predictionResults.map(
        ({ place_id: id, description: optionName }) => ({
          id,
          optionName,
        }),
      )}
      resultValue={addressName}
      onQuery={onInputChange}
      copy={SearchCopy}
      loading={loading}
      setLoading={setLoading}
    />
  );
};
