import { FunctionComponent } from "react";
import { InstantSearch } from "react-instantsearch";
import { APIProvider } from "@vis.gl/react-google-maps";
import { publicSearchClient } from "../../lib/algolia/clients";
import { standardizeIndexName } from "../../lib/algolia/indices";
import { CafeSearchHits } from "./CafeSearchHits";
import { CafeSearchLocator, CafeSearchLocatorProps } from "./CafeSearchLocator";

export const CafeSearch: FunctionComponent<
  CafeSearchLocatorProps & {
    isModule?: boolean;
  }
> = ({ isModule, cafeLocatorProps, ...props }) => (
  <APIProvider
    apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}
    authReferrerPolicy="origin"
    libraries={["places", "maps", "geometry"]}
  >
    <InstantSearch
      indexName={standardizeIndexName("cafes")}
      searchClient={publicSearchClient}
      insights
    >
      {isModule && (
        <CafeSearchLocator {...props} cafeLocatorProps={cafeLocatorProps} />
      )}

      {!isModule && <CafeSearchHits {...props} copy={cafeLocatorProps.copy} />}
    </InstantSearch>
  </APIProvider>
);
