import { useScreenSizeContext } from "@bluebottlecoffee/design-system/components";
import { AlgoliaCafe } from "../algolia/types";
import { CafeMarker } from "../../components/CafeSearch/CafeSearchMap";

/** Geo coordinates of the center of the contiguous USA */
export const centerOfUSA: google.maps.LatLngLiteral = {
  lat: 39.8283,
  lng: -96.5795,
};

export const centerOfUSAStr: string = "39.8283, -96.5795";

/** geopints containing the contiguous US */
export const boundingBoxUS = {
  east: 3.16723093729438,
  north: -136.4545344164958,
  south: 62.91741056183184,
  west: -63.68109691649579,
};

/** convert google maps LatLngLiteral object into a string */
export const boundsToStr = (bounds?: google.maps.LatLngBounds): string => {
  if (!bounds) return undefined;
  const ne = bounds?.getNorthEast();
  const sw = bounds?.getSouthWest();
  return `${sw?.lat()}, ${sw?.lng()}, ${ne?.lat()}, ${ne?.lng()}`;
};

/** transform AlgoliaCafe type to CafeMarker type */
export const hitToCafeMarker = (
  hit: AlgoliaCafe,
  lang: string,
): CafeMarker => ({
  name: hit.name[lang],
  position: {
    lat: hit.geoLocation.lat,
    lng: hit.geoLocation.lng,
  },
});

/** convert the geometry string into a google maps LatLngLiteral object */
export const geoStrToLatLngLiteral = (
  geo?: string,
): google.maps.LatLngLiteral | undefined => {
  const latLng: string[] | undefined = geo?.split(",");
  const lat: number | undefined = parseFloat(latLng?.[0]);
  const lng: number | undefined = parseFloat(latLng?.[1]);
  return lat && lng ? { lat, lng } : undefined;
};

/** get the distance between 2 geopoints using trigonometry */
export const getGeoDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  // convert degrees to radians
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  // convert back to degrees
  dist = (dist * 180) / Math.PI;
  // convert degrees to miles
  dist = dist * 60 * 1.1515;
  // convert miles to kilometers
  dist *= 1.609344;
  return dist;
};

/** determine the initial zoom level of the map based on the query and viewport */
export const getInitialMapZoom = (): number => {
  const { isDesktop, isLgTablet, isMobile, isSmTablet, is2Xl } =
    useScreenSizeContext();

  if (isMobile() || isLgTablet()) return 3;
  if (isSmTablet() || is2Xl() || isDesktop()) return 4;

  return 3; // Default zoom level
};

export type RouterQuery = {
  [key: string]: string;
};

/** update the url with new query */
export const handleUpdateURL = (mapGeometry: string, zoom: string) => {
  if (!mapGeometry) return null;

  const url = new URL(window.location.href);

  url.searchParams.set("geometry", mapGeometry);
  url.searchParams.set("zoom", zoom);
  url.searchParams.delete("placeId");

  return url;
};

// This function is used to determine the country based on the location
// It uses the Google Maps API to compute the distance between the given location and predefined country coordinates.
export const getCountry = (location: google.maps.LatLng) => {
  const countries = {
    US: {
      latLng: new google.maps.LatLng(38.7945952, -106.5348379),
      zoom: null, // handle by getInitialMapZoom
    },
    HK: {
      latLng: new google.maps.LatLng(22.3193039, 114.1693611),
      zoom: 13,
    },
    JP: {
      latLng: new google.maps.LatLng(36.204824, 138.252924),
      zoom: 6,
    },
  };

  const { computeDistanceBetween } = google.maps.geometry.spherical;
  const { US, HK, JP } = countries;

  const distanceFromUS = computeDistanceBetween(US.latLng, location);
  if (distanceFromUS === 0) return countries.US;

  const distanceFromHongKong = computeDistanceBetween(HK.latLng, location);
  if (distanceFromHongKong === 0) return countries.HK;

  const distanceFromJapan = computeDistanceBetween(JP.latLng, location);
  if (distanceFromJapan === 0) return countries.JP;

  return null;
};
