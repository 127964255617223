import { CafeLocatorResults } from "@bluebottlecoffee/design-system/components";
import { FunctionComponent, useMemo, useState } from "react";
import { useRouter } from "next/router";
import {
  useConfigure,
  UseConfigureProps,
  useInstantSearch,
} from "react-instantsearch";
import { useMap } from "@vis.gl/react-google-maps";
import { cafesIndexSearchableAttribute } from "../../lib/algolia/indices";
import { CafeMarker, CafeSearchMap } from "./CafeSearchMap";
import {
  boundsToStr,
  geoStrToLatLngLiteral,
  getGeoDistance,
  handleUpdateURL,
  hitToCafeMarker,
  RouterQuery,
} from "../../lib/component-utils/cafe-search-fns";
import { CafeSearchResult } from "./CafeSearchResult";
import { CafeLocatorFullCopy } from "../../lib/transformers/cafe-locator";
import { CafeSearchBar } from "./CafeSearchBar";

interface CafeSearchHitsProps {
  lang: string;
  region: string;
  copy: CafeLocatorFullCopy;
}

const GOOGLE_MAP_ID: string = "cafe-locator-google-map";

const cafeHitsCofig = (lang: string) => ({
  restrictSearchableAttributes: cafesIndexSearchableAttribute(lang),
  distinct: true,
  clickAnalytics: true,
});

export const CafeSearchHits: FunctionComponent<CafeSearchHitsProps> = ({
  copy,
  lang,
  region,
}) => {
  const [queryPresentation, setQueryPresentation] = useState<string>(null);

  const router = useRouter();
  const map = useMap(GOOGLE_MAP_ID);
  const {
    status,
    results: { hits },
  } = useInstantSearch();

  const isLoading = status !== "idle";

  const queries = router.query as RouterQuery;
  const { zoom } = queries;
  const { geometry } = queries;
  const insideBoundingBox = boundsToStr(map?.getBounds());

  const latLngLiteral = useMemo(() => {
    const geometryFormat = geometry?.replace(/[()]/g, "");
    return geoStrToLatLngLiteral(geometryFormat);
  }, [geometry]);

  const hitsWithDist = hits.map((hit) => {
    const dist = getGeoDistance(
      hit.geoLocation.lat,
      hit.geoLocation.lng,
      latLngLiteral?.lat,
      latLngLiteral?.lng,
    );

    return { ...hit, dist };
  });

  /** sort the hits by ascending distance from the query */
  const sortedHits = hitsWithDist.sort((a, b) => a.dist - b.dist);
  const cafeMarkers: CafeMarker[] = hits.map((hit) =>
    hitToCafeMarker(hit, lang),
  );

  const handleOnIdle = () => {
    const getZoom = String(map?.getZoom());
    const mapGeometry = map?.getCenter()?.toUrlValue();

    const url = handleUpdateURL(mapGeometry, getZoom);
    if (url) {
      router.replace(url, undefined, { shallow: true });
    }
  };

  // Set InstantSearch configuration (HITS)
  useConfigure({
    ...cafeHitsCofig(lang),
    hitsPerPage: insideBoundingBox ? 999 : 0,
    insideBoundingBox,
  } as UseConfigureProps);

  return (
    <CafeLocatorResults
      isLoading={isLoading}
      SearchBar={
        <CafeSearchBar
          mapId={GOOGLE_MAP_ID}
          newAddressName={queryPresentation}
          SearchCopy={{
            ...copy,
            noResultsDescription: copy.noCafesDescription,
          }}
        />
      }
      mapContainer={
        <CafeSearchMap
          cafeMarkers={cafeMarkers}
          initialZoom={Number(zoom)}
          initialCenter={latLngLiteral}
          onIdle={handleOnIdle}
          mapId={GOOGLE_MAP_ID}
          getCafeMarkerSelected={setQueryPresentation}
        />
      }
      searchResults={{
        cafes: sortedHits.map((hit) => (
          <CafeSearchResult
            key={hit.objectID}
            cafeCardProps={{
              imageAriaLabel: copy.imgLinkGenericAriaText,
              newWindowWarning: copy.newWindowWarning,
            }}
            cafeHoursCopy={copy}
            algoliaHit={hit}
            isModule={false}
            lang={lang}
            region={region}
            isCafeSearch
          />
        )),
        ...copy,
        ctaViewMore: copy.viewMoreText,
      }}
      copy={copy}
    />
  );
};
